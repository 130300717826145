<template>
    <a  href="#" class="dropdown-item"
        v-for="cita in citasConfirmadas" :key="cita">
        <div @click.prevent="cargarExpediente(cita.id_servicio,cita)" data-toggle="tooltip" data-placement="top" style="overflow-x:auto; display: flex; gap: 10px; justify-content: space-between;">
            <span  style="width: 100%;" ><i :class="cita.class"></i>
            <span v-tooltip="extractContent(cita.direccion_cliente)" >{{cita.codigo_servicio}}</span>
            </span>
            <span style="width: 100%; text-align:center">{{ cita.fecha_hora }}</span>
            <span style="width: 100%; text-align:end; display: flex; justify-content: space-between; align-items: center;">
                <span></span>
                <span>{{ cita.nombre_tramitador }}</span>
                <i @click="eliminarCita(cita.id_aviso)" style="color:red" class="fa fa-window-close"></i></span>
        </div>
    </a>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
export default({
    props:{
        citasConfirmadas: Array
    },
    setup() {
        
    },
    components:{
    },
    data(){
        return{
            formulario: false,
            citaselec: null,
            fecha:'',
            hora:'',
            motivo:'',
        }
    },
    methods: {
        async eliminarCita(id_aviso) {
           const api = new PwgsApi();
           let ids = [];
           ids.push(id_aviso);
            let subidadatos = {tipo:'solicita',ids_avisos:ids,estado:1,}
           await api.put('avisos/revisar-avisos', subidadatos);
           this.$parent.cargarConfirmadas();
        },
        cargarExpediente(id,cita){
            localStorage.setItem("tiposerv", 'cita');
            localStorage.setItem('nombreservicio' + id, cita.codigo_servicio);

            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
        extractContent(s) {
            var span = document.createElement('span');
            span.innerHTML = s;
            try {
                return decodeURIComponent(escape(span.textContent)) || decodeURIComponent(escape(span.innerText));
            }
            catch (error) {
                console.log(error);
                return s;
            }
        },
    },
})
</script>
